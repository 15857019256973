<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-alert variant="warning" v-model="showAlert">You need to be logged in to access that page. Please login.</b-alert>
                <b-alert variant="info" v-model="showLogoutAlert">You have logged out.</b-alert>
                <b-alert variant="danger" id="api-error" v-model="showAPIError">{{APIErrorText}}</b-alert>
                <b-form @submit.stop.prevent="onSubmit">
                  <h1>Login</h1>
                  <br>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input v-model="$v.form.username.$model" :state="validateState('username')" aria-describedby="username-feedback" type="text" class="form-control" placeholder="Username" autocomplete="off" name="username" />
                    <b-form-invalid-feedback id="username-feedback">This is a required field.</b-form-invalid-feedback>
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input v-model="$v.form.password.$model" :state="validateState('password')" aria-describedby="password-feedback" type="password" class="form-control" placeholder="Password" aautocomplete="off" name="password" />
                    <b-form-invalid-feedback id="password-feedback">This is a required field.</b-form-invalid-feedback>
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button type="submit" variant="primary" class="px-4">Login</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import store from '../store/index'

  export default {
    store,
    mixins: [validationMixin],
    name: 'Login',
    data () {
      return {
        form: {
          username: null,
          password: null
        },
        showAlert: this.$store.state.unAuthorisedAlert,
        showAPIError: false,
        APIErrorText: '',
        showLogoutAlert: this.$store.state.logoutAlert,
      }
    },
    validations: {
      form: {
        username: {
          required
        },
        password: {
          required
        }
      }
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onSubmit () {
        this.$store.state.logoutAlert = false;
        this.showAPIError = false;
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        const formData = {
          username: this.form.username,
          password: this.form.password,
        }
        this.$store.dispatch('login', formData).catch(error => {
          console.log(error);
          this.APIErrorText = error.data.non_field_errors[0];
          this.showAPIError = true;
        })
      },
      checkCodePush(){
        if(this.$store.getters.global_alert_type == "codepush"){
          this.APIErrorText = this.$store.getters.global_alert_text;
          this.showAPIError = true;
          this.$store.dispatch('unsetGlobalAlert');
        }
      }
    },
  beforeMount() {
    if (this.$store.getters.isAuthenticated) {
      var to = localStorage.getItem("redirect_to");
      if(to){
        localStorage.removeItem("redirect_to");
        return this.$router.push(to);
      } else {
        return this.$router.push("/dashboard")
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.checkCodePush()
    }, 100)
  }
}

</script>
